import React, { useState } from 'react';
import rdFlag from '../assets/img/rd-flag.png';
import usaFlag from '../assets/img/usa-flag.png';
import '../assets/styles/testimonials.css';
import Vimeo from '@u-wave/react-vimeo';

const Testimonials: React.FC = () => {
  const [activeVideo, setActiveVideo] = useState<number | null>(null);

  const togglePlay = (video: number) => {
    setActiveVideo(video);
  };

  return (
    <section id='testimonials' className='testimonios'>
      <div className='testimonial-main-box'>
        <p className='title'>TESTIMONIOS</p>
        <div className='main-text'>
          <p>
            «Todos seamos los líderes que deseamos tener»
            <br />
            <span>Simon Sinek</span>
          </p>
        </div>
        <div className='testimonios-grid'>
          <div className='testimonio-card'>
            {activeVideo === 1 ? (
              <Vimeo
                className='iframe-container'
                showTitle={false}
                showByline={false}
                showPortrait={false}
                video={994547067}
                autoplay
                loop
              />
            ) : (
              <div className='profile-overlay profile-overlay-of'>
                <div className='profile-details'>
                  <div className='profile-header'>
                    <img
                      loading='lazy'
                      src={usaFlag}
                      alt='Flag'
                      className='flag'
                    />
                    <h2>ORIANA FUENTES</h2>
                  </div>
                  <p>
                    CEO Mika Health
                    <br />
                    Co founder en Emptor
                  </p>
                </div>

                <div className='play-button' onClick={() => togglePlay(1)}>
                  <svg viewBox='0 0 24 24'>
                    <circle cx='12' cy='12' r='12' fill='blue' />
                    <polygon points='10,8 16,12 10,16' fill='white' />
                  </svg>
                </div>
              </div>
            )}
          </div>

          <div className='testimonio-card'>
            {activeVideo === 2 ? (
              <Vimeo
                className='iframe-container'
                video={994547816}
                autoplay
                loop
                showTitle={false}
                showByline={false}
                showPortrait={false}
              />
            ) : (
              <div className='profile-overlay profile-overlay-jm'>
                <div className='profile-details'>
                  <div className='profile-header'>
                    <img
                      loading='lazy'
                      src={rdFlag}
                      alt='Flag'
                      className='flag'
                    />
                    <h2>YOSILENNY MARTÍNEZ</h2>
                  </div>
                  <p>Fundadora y directora de centro Paz Mental</p>
                </div>

                <div className='play-button' onClick={() => togglePlay(2)}>
                  <svg viewBox='0 0 24 24'>
                    <circle cx='12' cy='12' r='12' fill='blue' />
                    <polygon points='10,8 16,12 10,16' fill='white' />
                  </svg>
                </div>
              </div>
            )}
          </div>

          <div className='testimonio-card'>
            {activeVideo === 3 ? (
              <Vimeo
                className='iframe-container'
                video={994545689}
                autoplay
                loop
                showTitle={false}
                showByline={false}
                showPortrait={false}
              />
            ) : (
              <div className='profile-overlay profile-overlay-es'>
                <div className='profile-details'>
                  <div className='profile-header'>
                    <img
                      loading='lazy'
                      src={rdFlag}
                      alt='Flag'
                      className='flag'
                    />
                    <h2>EZEQUIEL SALAS</h2>
                  </div>
                  <p>Empresario / Software Engineer</p>
                </div>

                <div className='play-button' onClick={() => togglePlay(3)}>
                  <svg viewBox='0 0 24 24'>
                    <circle cx='12' cy='12' r='12' fill='blue' />
                    <polygon points='10,8 16,12 10,16' fill='white' />
                  </svg>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
