import React, { useEffect, useMemo, useState } from 'react';
import '../assets/styles/header.css';
import logo from '../assets/img/logo.png';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [navColor, setNavColor] = useState('transparent');

  const transparentSection = useMemo(
    () => ['banner', 'testimonials', 'program', 'footer'],
    []
  );

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let currentSectionId = '';

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom >= 0) {
          currentSectionId = section.id;
        }
      });

      if (transparentSection.includes(currentSectionId)) {
        setNavColor('rgba(20, 20, 20, 0.2)');
      } else {
        setNavColor('rgb(20, 20, 20)');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen, transparentSection]);

  return (
    <header style={{ backgroundColor: navColor }}>
      <div className='header-main-box'>
        <img loading='lazy' className='logo' src={logo} alt='no img' />
        <div className='menu'>
          <ul>
            <li className='list'>
              <a href='#banner'>Propósito</a>
            </li>
            <li className='list'>
              <a href='#intro'>Viaje 2.0</a>
            </li>
            <li className='list'>
              <a href='#testimonials'>Testimonios</a>
            </li>
            <li className='list'>
              <a href='#method'>Método</a>
            </li>
            <li className='list'>
              <a href='#program'>¿Para quién?</a>
            </li>
            <li>
              <a
                className='btn-register'
                href='https://wa.me/51940749704'
                target='_blank'
                rel='noreferrer'
              >
                Regístrate Ahora
              </a>
            </li>
          </ul>
        </div>
        <button
          className='menu-icon'
          aria-label='Toggle menu'
          onClick={toggleMenu}
        >
          ☰
        </button>
        <nav className={`overlay ${menuOpen ? 'open' : ''}`}>
          <button
            className='close-icon'
            onClick={toggleMenu}
            aria-label='Close menu'
          >
            ✕
          </button>
          <ul>
            <a href='#banner' onClick={toggleMenu}>
              <li>Propósito</li>
            </a>
            <a href='#intro' onClick={toggleMenu}>
              <li>Viaje 2.0</li>
            </a>
            <a href='#testimonials' onClick={toggleMenu}>
              <li>Testimonios</li>
            </a>
            <a href='#method' onClick={toggleMenu}>
              <li>Método</li>
            </a>
            <a href='#program' onClick={toggleMenu}>
              <li>¿Para quién?</li>
            </a>
            <a
              href='https://wa.me/51940749704'
              target='_blank'
              rel='noreferrer'
              onClick={toggleMenu}
            >
              <li>Registrate</li>
            </a>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
