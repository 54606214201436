import React from 'react';
import '../assets/styles/program.css';

const Program: React.FC = () => {
  return (
    <section id='program' className='programa'>
      <div className='program-main-box'>
        <p className='title'>
          Este programa es
          <br />
          <span className='text-container'>
            <span className='scribble'>para tí</span>
            <span>para tí si...</span>
          </span>
        </p>

        <ul className='fade-in-text'>
          <div>
            <li>
              <svg
                width='49'
                height='45'
                viewBox='0 0 49 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 22.5L17.5 29L47.5 1'
                  stroke='white'
                  stroke-width='2'
                />
                <circle cx='18' cy='26.5' r='17.5' stroke='white' />
              </svg>
              <p>
                Quieres un <strong>puesto de liderazgo</strong> en tu empresa.
              </p>
            </li>

            <li>
              <svg
                width='49'
                height='45'
                viewBox='0 0 49 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 22.5L17.5 29L47.5 1'
                  stroke='white'
                  stroke-width='2'
                />
                <circle cx='18' cy='26.5' r='17.5' stroke='white' />
              </svg>
              <p>
                <strong>No sabes como convertirte en el líder </strong>que
                siempre quisiste ser.
              </p>
            </li>

            <li>
              <svg
                width='49'
                height='45'
                viewBox='0 0 49 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 22.5L17.5 29L47.5 1'
                  stroke='white'
                  stroke-width='2'
                />
                <circle cx='18' cy='26.5' r='17.5' stroke='white' />
              </svg>
              <p>
                <strong>Tienes una posición de Liderazgo</strong> pero no tienes más
                herramientas para mejorar.
              </p>
            </li>
          </div>
          <div>
            <li>
              <svg
                width='49'
                height='45'
                viewBox='0 0 49 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 22.5L17.5 29L47.5 1'
                  stroke='white'
                  stroke-width='2'
                />
                <circle cx='18' cy='26.5' r='17.5' stroke='white' />
              </svg>
              <p>
                <strong>Te sientes abrumado/a</strong> con la vida que llevas
                actualmente.
              </p>
            </li>

            <li>
              <svg
                width='49'
                height='45'
                viewBox='0 0 49 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 22.5L17.5 29L47.5 1'
                  stroke='white'
                  stroke-width='2'
                />
                <circle cx='18' cy='26.5' r='17.5' stroke='white' />
              </svg>
              <p>
                Quieres <strong> mejorar tus oportunidades</strong> laborales.
              </p>
            </li>

            <li>
              <svg
                width='49'
                height='45'
                viewBox='0 0 49 45'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13 22.5L17.5 29L47.5 1'
                  stroke='white'
                  stroke-width='2'
                />
                <circle cx='18' cy='26.5' r='17.5' stroke='white' />
              </svg>
              <p>
                Sientes que <strong>necesitas un giro en tu vida</strong>.
              </p>
            </li>
          </div>
        </ul>
      </div>
    </section>
  );
};

export default Program;
