import React from 'react';
import '../assets/styles/banner.css';

const Banner: React.FC = () => {
  return (
    <section id='banner' className='banner'>
      <div className='banner-main-box fade-in-text'>
        <div className='banner-main-text'>
          <p>
            Te <span className='border-box'>acompañamos</span>
          </p>
          <p>a convertirte en tu</p>
          <p>
            <span className='border-box'>mejor versión</span>
          </p>
           
          <a
            className='register-btn'
            href='https://wa.me/51940749704'
            target='_blank'
            rel='noreferrer'
          >
            ¡Regístrate ahora!
          </a>
        </div>

        <div className='banner-circles'>
          <svg
            viewBox='0 0 423 311'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clip-path='url(#clip0_2101_21)'>
              <path
                d='M154.807 310.186C240.094 310.186 309.233 240.932 309.233 155.504C309.233 70.0754 240.094 0.822021 154.807 0.822021C69.5191 0.822021 0.379883 70.0754 0.379883 155.504C0.379883 240.932 69.5191 310.186 154.807 310.186Z'
                stroke='#F9F9F9'
                stroke-miterlimit='10'
              />
              <path
                d='M202.009 309.744C287.297 309.744 356.436 240.491 356.436 155.062C356.436 69.634 287.297 0.380615 202.009 0.380615C116.722 0.380615 47.5825 69.634 47.5825 155.062C47.5825 240.491 116.722 309.744 202.009 309.744Z'
                stroke='#F9F9F9'
                stroke-miterlimit='10'
              />
              <path
                d='M268.193 310.627C353.481 310.627 422.62 241.374 422.62 155.945C422.62 70.5168 353.481 1.26343 268.193 1.26343C182.906 1.26343 113.767 70.5168 113.767 155.945C113.767 241.374 182.906 310.627 268.193 310.627Z'
                stroke='#F9F9F9'
                stroke-miterlimit='10'
              />
            </g>
            <defs>
              <clipPath id='clip0_2101_21'>
                <rect width='423' height='311' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className='arrow-container'>
          <svg
            className='arrow'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M-10 3 L13 20 L34 3'
              stroke='white'
              stroke-width='3'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
          <svg
            className='arrow'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M-10 3 L13 20 L34 3'
              stroke='white'
              stroke-width='3'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
          <svg
            className='arrow'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M-10 3 L13 20 L34 3'
              stroke='white'
              stroke-width='3'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default Banner;
