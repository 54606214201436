import React from 'react';
import './assets/styles/App.css';
import Header from './components/header';
import Intro from './components/intro';
import Testimonials from './components/testimonials';
import Method from './components/method';
import Program from './components/program';
import Footer from './components/footer';
import Banner from './components/banner';
import Time from './components/time';

const App: React.FC = () => {
  return (
    <div className='App'>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
      </style>

      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
      </style>
      <Header />
      <Banner />
      <Intro />
      <Testimonials />
      <Method />
      <Program />
      <Time />
      <Footer />
    </div>
  );
};

export default App;
