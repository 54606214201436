import React, { useState } from 'react';
import '../assets/styles/method.css';

const Method: React.FC = () => {
  const [isExpanded0, setIsExpanded0] = useState(true);
  const [isExpanded1, setIsExpanded1] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  const toggleExpand0 = () => {
    setIsExpanded0(!isExpanded0);
  };

  const toggleExpand1 = () => {
    setIsExpanded1(!isExpanded1);
  };

  const toggleExpand2 = () => {
    setIsExpanded2(!isExpanded2);
  };

  return (
    <section id='method' className='metodo'>
      <h2 className='title'>MÉTODO</h2>
      <div className='metodo-main-box'>
        <div className='metodo-main-text'>
          <p className='sub-title'>
            Aprende a
            <span className='text-container'>
              <span className='scribble'>LIDERAR</span>
              <span>LIDERAR</span>
            </span>
            <br /> con el poder del <br /> Autoliderazgo.
          </p>

          <br />
          <p className='main-text'>
            La forma en la que una persona se relaciona con el exterior es solo
            <strong> el reflejo de la relación que tiene consigo misma.</strong>
            <br />
            <br />
            El trabajo profundo en uno mismo nos da las
            <strong> mejores herramientas para liderar a otros.</strong>
          </p>
        </div>
      </div>
      <div className='modulos full-box'>
        <h3 className='title'>3 Efectivos Módulos</h3>

        <div className='list-container'>
          <div className='list-item'>
            <div className='line'></div>
            <span>AUTOLIDERAZGO</span>
            <svg
              className='arrow-icon'
              viewBox='0 0 24 24'
              onClick={toggleExpand0}
            >
              <path
                d='M6 9l6 6 6-6'
                stroke='white'
                strokeWidth='2'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          {isExpanded0 && (
            <div className='list-item-content'>
              <p>
                <span>
                  Te desafiarás para crear la identidad que deseas desde las
                  bases
                </span>
                , apoyadas de las 5 competencias de la Inteligencia Emocional.
                <br />
                Comprenderás cómo funcionan tus emociones y cómo gestionarás
                para que sean tus mejores aliados.
              </p>
            </div>
          )}

          <div className='list-item'>
            <div className='line'></div>
            <span>EL ARTE DE LIDERAR</span>
            <svg
              className='arrow-icon'
              viewBox='0 0 24 24'
              onClick={toggleExpand1}
            >
              <path
                d='M6 9l6 6 6-6'
                stroke='white'
                strokeWidth='2'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          {isExpanded1 && (
            <div className='list-item-content'>
              <p>
                Conocerás los secretos de la experiencia, para que puedas saber
                en qué momento usar las herramientas y estrategias correctas de
                acuerdo con el equipo que estés liderando.{' '}
                <strong>
                  Te anticiparás a los desafíos del comportamiento social y
                  demostrarás tu destreza liderando.
                </strong>
              </p>
            </div>
          )}
          <div className='list-item'>
            <div className='line'></div>
            <span>EQUIPOS DE ALTO RENDIMIENTO</span>
            <svg
              className='arrow-icon'
              viewBox='0 0 24 24'
              onClick={toggleExpand2}
            >
              <path
                d='M6 9l6 6 6-6'
                stroke='white'
                strokeWidth='2'
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          {isExpanded2 && (
            <div className='list-item-content'>
              <p>
                Dominarás los pilares para acompañar a tu equipo a convertirse
                en uno de <strong>alto rendimiento</strong> de manera sostenible
                y sin que eso signifique tu autosacrificio. Disfrutarás del
                liderazgo desde una nueva visión y un nuevo tú.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Method;
