import React from 'react';
import '../assets/styles/footer.css';
import phone from '../assets/img/phone.png';

const Footer: React.FC = () => {
  return (
    <section id='footer' className='footer'>
      <div className='footer-main-box'>
        <div className='footer-main-text'>
          <p className='title'>¡Síguenos!</p>
          <br />
          <p>
            Para seguir aprendiendo como <br />
            <strong>convertirte en tu mejor versión</strong>
          </p>

          <div className='social-media'>
            <a
              href='https://www.linkedin.com/company/lider-2-0/'
              target='_blank'
              rel='noreferrer'
            >
              <svg
                viewBox='0 0 89 89'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_2101_138)'>
                  <path
                    d='M37.6802 64.5298V37.6698H45.5002V40.8598C45.6102 40.9098 45.7202 40.9698 45.8302 41.0198C46.0602 40.7198 46.2702 40.4098 46.5102 40.1198C50.0102 36.0198 60.3402 35.8298 63.3002 42.0998C63.8602 43.2798 64.3402 44.6198 64.3702 45.8998C64.4802 51.9298 64.4202 57.9598 64.4202 63.9998C64.4202 64.1098 64.3802 64.2298 64.3402 64.4398H56.5202C56.5202 63.9098 56.5202 63.4098 56.5202 62.9198C56.5202 58.6098 56.5602 54.2998 56.5002 49.9898C56.4602 47.2798 54.8402 45.1998 52.5202 44.6698C50.0102 44.0898 47.3402 45.2598 46.2002 47.6098C45.7902 48.4598 45.5702 49.4798 45.5602 50.4298C45.4902 54.7398 45.5102 59.0498 45.5602 63.3598C45.5602 64.2698 45.3402 64.5998 44.3802 64.5698C42.1902 64.4998 40.0002 64.5498 37.6802 64.5498V64.5298Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M24.9702 37.6602H32.7802V64.4302H24.9702V37.6602Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M28.8501 23.93C31.5601 23.95 33.6701 26.11 33.6301 28.84C33.6001 31.5 31.4601 33.6 28.8101 33.6C26.1101 33.6 24.0001 31.42 24.0401 28.68C24.0801 25.99 26.1901 23.9 28.8501 23.93Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M44.24 87.98C68.3969 87.98 87.98 68.3969 87.98 44.24C87.98 20.0831 68.3969 0.5 44.24 0.5C20.0831 0.5 0.5 20.0831 0.5 44.24C0.5 68.3969 20.0831 87.98 44.24 87.98Z'
                    stroke='#F9F9F9'
                    stroke-miterlimit='10'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2101_138'>
                    <rect width='88.48' height='88.48' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href='https://www.instagram.com/lider2.0_?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='
              target='_blank'
              rel='noreferrer'
            >
              <svg
                viewBox='0 0 89 89'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_2101_144)'>
                  <path
                    d='M22.77 44.2102C22.77 40.8802 22.77 37.5502 22.77 34.2302C22.77 28.1602 27.88 22.9002 33.96 22.8402C40.8 22.7802 47.64 22.7902 54.48 22.8402C60.25 22.8902 65.4 27.6802 65.57 33.4702C65.78 40.6402 65.76 47.8202 65.57 54.9902C65.42 60.6302 60.49 65.4302 54.79 65.5602C47.77 65.7302 40.74 65.7502 33.72 65.5602C27.57 65.4002 22.8 60.2202 22.78 54.0702C22.77 50.7802 22.78 47.4902 22.78 44.2002L22.77 44.2102ZM44.18 26.4902C41.11 26.4902 38.04 26.4902 34.97 26.4902C29.81 26.4902 26.44 29.8702 26.43 35.0402C26.43 41.2102 26.43 47.3902 26.43 53.5602C26.43 58.4102 29.84 61.9102 34.7 61.9302C41.02 61.9602 47.34 61.9502 53.67 61.9302C58.58 61.9102 61.98 58.5002 61.98 53.6102C61.98 47.3602 61.98 41.1102 61.98 34.8602C61.98 29.9102 58.58 26.5102 53.61 26.4802C50.47 26.4602 47.32 26.4802 44.18 26.4802V26.4902Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M44.2599 55.8804C37.8199 55.8904 32.6099 50.7104 32.5899 44.2704C32.5699 37.8504 37.7999 32.5703 44.1799 32.5503C50.5599 32.5303 55.9299 37.8104 55.9599 44.1304C55.9899 50.5604 50.7199 55.8603 44.2599 55.8703V55.8804ZM44.1999 36.5303C39.9899 36.5303 36.4699 40.0404 36.4799 44.2204C36.4899 48.5204 39.9599 52.0103 44.2199 52.0103C48.4599 52.0103 51.9399 48.4904 51.9399 44.2004C51.9399 40.0304 48.4099 36.5304 44.1999 36.5404V36.5303Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M55.7101 30.3101C57.0501 30.3001 58.1901 31.4401 58.1801 32.7701C58.1701 34.0601 57.0801 35.1501 55.7801 35.2001C54.4301 35.2401 53.3001 34.1601 53.2701 32.7901C53.2401 31.4401 54.3301 30.3301 55.7101 30.3201V30.3101Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M44.24 87.98C68.3969 87.98 87.98 68.3969 87.98 44.24C87.98 20.0831 68.3969 0.5 44.24 0.5C20.0831 0.5 0.5 20.0831 0.5 44.24C0.5 68.3969 20.0831 87.98 44.24 87.98Z'
                    stroke='#F9F9F9'
                    stroke-miterlimit='10'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2101_144'>
                    <rect width='88.48' height='88.48' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a
              href='https://www.facebook.com/share/WqFANHNTKdAtN1Gi/?mibextid=LQQJ4d'
              target='_blank'
              rel='noreferrer'
            >
              <svg
                width='89'
                height='89'
                viewBox='0 0 89 89'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_2004_235)'>
                  <path
                    d='M47.8501 64.28H40.1001V46.4H33.5601V39.17H40.0701C40.0701 38.6 40.0501 38.14 40.0701 37.68C40.1701 35.4 40.0201 33.07 40.4501 30.85C41.3201 26.35 44.5601 24.02 49.1201 24.21C51.0301 24.29 52.9301 24.37 54.9101 24.46V30.45C54.0101 30.45 53.1101 30.41 52.2101 30.46C51.4701 30.51 50.7001 30.57 50.0001 30.8C48.5601 31.27 47.8401 32.36 47.8201 33.87C47.8101 35.34 47.8401 36.82 47.8101 38.29C47.7901 38.93 47.9801 39.17 48.6601 39.16C50.2901 39.12 51.9201 39.15 53.5601 39.15C53.9001 39.15 54.2301 39.15 54.7201 39.15C54.3301 41.62 53.9701 43.96 53.5801 46.39H47.8501V64.28Z'
                    fill='#F9F9F9'
                  />
                  <path
                    d='M44.24 87.98C68.3969 87.98 87.98 68.3969 87.98 44.24C87.98 20.0831 68.3969 0.5 44.24 0.5C20.0831 0.5 0.5 20.0831 0.5 44.24C0.5 68.3969 20.0831 87.98 44.24 87.98Z'
                    stroke='#F9F9F9'
                    stroke-miterlimit='10'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2004_235'>
                    <rect width='88.48' height='88.48' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
        <img loading='lazy' src={phone} alt='' />
      </div>
      <div className='text-footer'>
        <p>MADE BY LIDER 2.0</p>
      </div>
    </section>
  );
};

export default Footer;
