import React from 'react';
import '../assets/styles/intro.css';
import karlalider from '../assets/img/karla_lider.png';

const Intro: React.FC = () => {
  return (
    <section id='intro' className='intro'>
      <div className='intro-main-box'>
        <div className='intro-content'>
          <div className='title'>
            <p>Líder 2.0 es un</p>
            <p>
              <span className='text-container'>
                <span className='scribble'>viaje único </span>
                <span>viaje único </span>
              </span>
              <span> en el </span>
            </p>
            <p>mundo,</p>
          </div>
          <p className='text'>
            con más de 13 años de estudios y experiencia práctica formando
            líderes, Karla Rejas ha creado un impactante e innovador programa
            para que todos puedan convertirse en Líderes de Alto Impacto en
            corto tiempo.
          </p>
          <div className='line-with-dots'>
            <div className='line'></div>
            <div className='dot-right'></div>
          </div>
          <p className='text'>
          Karla es inversionista y CEO de MOMO, especialista en inteligencia emocional, desarrollo de habiilidades blandas y liderazgo, comprometida con el bienestar personal. Con una amplia experiencia internacional (EEUU, México, Ecuador, República Dominicana, Perú, Uganda, Pakistan, entre otros).
          </p>
          <div className='line-with-dots'>
          <div className='line'></div>
            <div className='dot-left'></div>
          </div>
        </div>
        <img loading='lazy' src={karlalider} alt='' />
      </div>
    </section>
  );
};

export default Intro;
